import type { AsyncDataOptions } from "#app";

import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

type PrizeDropsResponse = Required<ExtractFromAPI<v1.paths, "/rest/prize-drops/info/", "get">>;

const defaultData = {
	general: {
		dailyPrizePool: 100000,
		highestPrizePerDay: 5000,
		prizeCountPerDay: 500,
		minimalLevelPlay: 20,
		finishedAt: "",
		nextPrizePoolStartedAt: ""
	},
	prizes: [],
	lastWinners: []
};

const useGetPrizeDropsInfoData = ({
	reversePrizes = true,
	immediate = true,
	cached = true,
	...restOptions
}: AsyncDataOptions<PrizeDropsResponse> & { cached?: true; reversePrizes?: boolean } = {}) => {
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/prize-drops/info/",
		method: "get",
		options: {
			cached: !cached ? undefined : true,
			server: restOptions?.server,
			immediate: immediate && !isGuest.value,
			watch: [isGuest, ...(restOptions?.watch || [])],
			default: () => defaultData,
			transform: (data) => ({
				...data,
				prizes: reversePrizes ? data?.prizes?.reverse() : data?.prizes
			})
		}
	});
};

export default useGetPrizeDropsInfoData;
